requirejs.config({
    paths: {
        jquery: '/build/base/js/lib/jquery.min',

        // COMPONENTS
        lazysizes: '/build/bve/js/components/lazy',
        offcanvas: '/build/bve/js/components/offcanvas-navigation',
        scrolltop: '/build/bve/js/components/scrolltop',
        stickyjs: '/build/bve/js/components/sticky',
        slick: '/build/base/js/components/slick',

        // LIBRARIES
        lib_aos: '/build/base/js/lib/aos',
        lib_stickyjs: '/build/base/js/lib/sticky',
        lib_slick: '/build/base/js/lib/slick',
        lib_lazysizes: '/build/base/js/lib/lazysizes/slick',
        lib_photoswipe: '/build/base/js/lib/photoswipe/photoswipe',
        lib_photoswipe_ui: '/build/base/js/lib/photoswipe/photoswipe-ui-default'
    }
});

